/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* Import Montserrat font from Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap");

/* Tailwind CSS Directives */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #ffffff;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #808080;
    border-radius: 100vh;
    border: 3px solid #f6f7ed;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #808080;
  }
}

.markdown-content .markdown-table {
  border-collapse: collapse;
  width: 100%;
  margin: 1rem 0;
  font-size: 0.9em;
}

.markdown-content .markdown-table th,
.markdown-content .markdown-table td {
  border: 1px solid #e2e8f0;
  padding: 0.5rem;
  text-align: left;
}

.markdown-content .markdown-table th {
  background-color: #f7fafc;
  font-weight: bold;
}

.markdown-content pre {
  background-color: #f7fafc;
  border-radius: 0.25rem;
  padding: 1rem;
  overflow-x: auto;
}

.markdown-content code {
  background-color: #f7fafc;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  font-family: monospace;
}

.markdown-content p {
  margin-bottom: 1rem;
}

.markdown-content ul,
.markdown-content ol {
  margin-bottom: 1rem;
  padding-left: 1.5rem;
}

.markdown-content li {
  margin-bottom: 0.5rem;
}
@media (max-width: 768px) {
  .popover {
    /* background-color: #61dafb; */
    cursor: pointer !important;
    z-index: 9999; /* Popup should have the highest z-index on mobile */
  }

  /* .sidebar {
    z-index: 100;
  } */
}

/* Using a class */
.custom-checkbox[type="checkbox"] {
  accent-color: #25408f !important;
  background-color: #25408f !important;
}

.custom-checkbox[type="checkbox"]:checked {
  -webkit-appearance: checkbox !important;
}

@media (max-width: 768px) {
  .truncate {
    max-width: calc(100% - 50px); /* Reserve space for the button */
  }

  button {
    flex-shrink: 0; /* Ensure the button remains visible */
    display: block;
  }
}
